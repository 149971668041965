import React from "react";
import PropTypes from "prop-types";

const NotFound = (props) => {
  return <div>NotFound</div>;
};

NotFound.propTypes = {};

export default NotFound;
